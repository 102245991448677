<template>
  <div :class="rootClassName">
    <slot name="before">
    </slot>
    <label :class="{'active': this.value}" v-if="label && inputClassName === 'md-form-control'">{{ label }}</label>

    <div class="dropfile md-form col-md-12 no-padding ">
      <vue-dropzone ref="fileDropzone" id="fileDropzone"
        @vdropzone-complete="handleComplete"
        @vdropzone-removed-file="handleRemove"
        @vdropzone-success="handleSuccess"
        v-on:vdropzone-sending="sendingEvent"
        :options="dropzoneOptions"></vue-dropzone>
    </div>

    <transition name="slide" mode="">
      <small v-if="error" class="royalc-error-field">{{ error }}</small>
    </transition>

    <slot name="after"></slot>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  inheritAttrs: false,
  name: 'CustonFileUpload',
  data () {
    return {
      dropzoneOptions: {
        url: this.url,
        thumbnailWidth: 150,
        maxFilesize: this.maxFilesize,
        maxFiles: this.maxFiles,
        acceptedFiles: this.acceptedFiles,
        dictDefaultMessage: this.defaultMessage,
        addRemoveLinks: true,
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      refName: this.refInput || 'field_' + Math.random().toString().substr(2),
      activeLabel: false,
      filesLoaded: null
    }
  },
  components: {
    vueDropzone: vue2Dropzone
  },
  mounted () {
    this.filesLoaded = false
  },
  $_veeValidate: {
    // value getter
    value () {
      return this.$el.value
    },
    // name getter
    name () {
      return this.name
    }
  },
  props: {
    url: {
      type: String,
      default: process.env.VUE_APP_APIENDPOINT + 'v2/file/upload'
    },
    defaultMessage: {
      type: String,
      default: '<div class="dz-message"><i class="ti-import text-info font-40"></i>' +
        '  <div class="mt-3 font-20">' + global.instanceApp.$i18n.t('Drop files here or click to upload') + '</div>' +
        '</div>'
    },
    refInput: String,
    name: String,
    value: {
      type: null,
      default: null
    },
    maxFilesize: {
      type: Number,
      default: 10.0
    },
    acceptedFiles: {
      type: String,
      default: null
    },
    maxFiles: {
      type: Number,
      default: null
    },
    vMask: {
      type: null,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null,
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    rootClassName: {
      type: String,
      default: 'form-group'
    },
    inputClassName: {
      type: String,
      default: 'form-control'
    },
    uploadSuccess: {
      type: Function,
      required: false,
      default: () => {
        return false
      }
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      let _this = this

      if (_this.$refs.fileDropzone && !_this.filesLoaded) {
        for (let index in _this.value) {
          let value = _this.value[index]
          if (value.temp !== true) {
            let file = { name: value.originalFilename, type: value.type, temp: value.temp, size: value.size }
            let url = process.env.VUE_APP_APIBaseURL + '/uploads/files/' + value.filename + '?' + (new Date().getTime())
            _this.$refs.fileDropzone.manuallyAddFile(file, url)
          }
        }
        _this.filesLoaded = true
      }

      if (newValue === null || newValue.length <= 0) {
        _this.$refs.fileDropzone.removeAllFiles()
        _this.filesLoaded = false
      }
    }
  },
  methods: {
    handleSuccess (file, response) {
      if (file.status === 'success') {
        this.uploadSuccess(this.$refs.fileDropzone, file, response)
      }
    },
    handleRemove (response, error, xhr) {
      if (response.accepted) {
        let removedObject = this.$jQuery.parseJSON(response.xhr.response)

        // Find position of removed item
        let index = this.value.findIndex(function (element) {
          return element['filename'] === removedObject.data.filename
        })
        // remove element from selected options
        this.value.splice(index, 1)
      } else if (response.manuallyAdded) {
        // Find position of removed item
        let index = this.value.findIndex(function (element) {
          return element['originalFilename'] === response.name
        })

        // remove element from selected options
        this.value.splice(index, 1)
      }
    },
    handleComplete (response) {
      if (response.accepted) {
        let object = this.$jQuery.parseJSON(response.xhr.response)
        this.value.push(object.data)
      }
    },
    handleInput (e) {
      this.$emit('input', e.target.value)
    },
    uploadingFiles () {
      if (this.$refs.fileDropzone) {
        return this.$refs.fileDropzone.getUploadingFiles()
      }
    },
    sendingEvent (file, xhr, formData) {
      formData.append('preview', this.preview)
    }
  }
}
</script>

<style scoped>
  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.3s;
  }
  .slide-enter,
  .slide-leave-to {
    opacity: 0;
  }

  .no-margin {
    margin: 0 !important;
    border: 0;
  }
</style>
